<script>
function areSizePropsValid(size, width, height) {
  if (size !== 0) {
    return true;
  }

  if (width > 0 && height > 0) {
    return true;
  }

  return false;
}

const SIZES = [0, 8, 12, 16, 18, 20, 24, 32, 48, 64];

export default {
  props: {
    size: {
      required: false,
      type: Number,
      default: 12,
      validator(size) {
        if (!size) {
          return true;
        }

        if (SIZES.includes(size)) {
          return true;
        }

        return false;
      },
    },
    width: {
      required: false,
      type: Number,
      default: null,
    },
    height: {
      required: false,
      type: Number,
      default: null,
    },
  },

  computed: {
    iconClass() {
      const classes = [
        'svg-icon',
      ];

      const {
        size,
        styles,
      } = this;

      if (size && !styles) {
        classes.push(`svg-icon-size-${size}`);
      }

      return classes;
    },

    styles() {
      const {
        width,
        height,
      } = this;

      if (!width) {
        return null;
      }

      if (!height) {
        return null;
      }

      return {
        width: `${width}px`,
        height: `${height}px`,
      };
    },
  },

  created() {
    if (!this.$options.iconName) {
      throw new Error('Cannot create instance of BaseIcon. Provide iconName in options.');
    }

    if (!areSizePropsValid(this.size, this.width, this.height)) {
      throw new Error('You need to either pass size greater then 0 or provide both width and height');
    }
  },
};
</script>
