<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-black">
    <div class="container-fluid">
      <a
        class="navbar-brand d-flex align-items-center"
        href="/"
      >
        <icon-logo
          class="me-2"
          :height="32"
          :width="32"
        />

        Fútbol - hoy en vivo 🇲🇽
      </a>

      <button
        aria-controls="navbarNav"
        :aria-expanded="show"
        aria-label="Toggle navigation"
        class="navbar-toggler"
        :class="{ collapsed: !show }"
        data-bs-target="#navbarNav"
        data-bs-toggle="collapse"
        type="button"
        @click="toggle"
      >
        <span class="navbar-toggler-icon"/>
      </button>

      <div
        id="navbarNav"
        class="navbar-collapse"
        :class="{ collapse, collapsing, show }"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <nuxt-link
              aria-current="page"
              class="nav-link"
              to="/"
            >
              {{ $t('navbar.top.home') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import IconLogo from '~/components/icons/IconLogo.vue';

export default {
  name: 'TopNav',

  components: {
    IconLogo,
  },

  data: () => ({
    show: false,
    collapsing: false,

    timeout: null,
  }),

  computed: {
    collapse() {
      if (this.collapsing) {
        return false;
      }

      return true;
    },
  },

  methods: {
    toggle() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.collapsing = true;

      this.timeout = setTimeout(() => {
        this.collapsing = false;
        this.show = !this.show;
      }, 400);
    },
  },
};
</script>
