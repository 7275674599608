export function isClient() {
  if (typeof window === 'undefined') {
    return false;
  }

  if (!window.document) {
    return false;
  }

  return true;
}
