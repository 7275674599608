function fakeCache(key, fn, maxAge) {
  return fn();
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      cache: fakeCache,
    },
  };
});
