export function getValue(item, prop) {
  if (typeof prop === 'function') {
    return prop(item);
  }
  return item[prop];
}

export function removeEmptyFields(obj) {
  return Object
    .entries(obj)
    .reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }

      return acc;
    }, {});
}

export function hashCode(value) {
  const str = typeof value === 'object' ? JSON.stringify(value) : value;
  let hash = 0;
  const l = str.length;
  if (str.length === 0) {
    return hash;
  }

  for (let i = 0; i < l; i++) {
    const char = str.charCodeAt(i);

    hash = ((hash << 5) - hash) + char;

    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function stripTags(html) {
  return html.replace(/<style[^>]*>.*<\/style>/gm, '')
  // Remove script tags and content
    .replace(/<script[^>]*>.*<\/script>/gm, '')
  // Remove all opening, closing and orphan HTML tags
    .replace(/<[^>]+>/gm, '')
  // Remove leading spaces and repeated CR/LF
    .replace(/([\r\n]+ +)+/gm, '');
}

export function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export function executeRandomly(percentage, callback) {
  const randomNumber = getRandomNumber(0, 100);

  if (randomNumber > percentage) {
    return;
  }

  callback();
}

// export function generateHeadLinks(basePath) {
//   return [
//     {
//       rel: 'apple-touch-icon',
//       sizes: '57x57',
//       href: `${basePath}favicons/apple-icon-57x57.png`,
//     },
//     {
//       rel: 'apple-touch-icon',
//       sizes: '60x60',
//       href: `${basePath}favicons/apple-icon-60x60.png`,
//     },
//     {
//       rel: 'apple-touch-icon',
//       sizes: '72x72',
//       href: `${basePath}favicons/apple-icon-72x72.png`,
//     },
//     {
//       rel: 'apple-touch-icon',
//       sizes: '76x76',
//       href: `${basePath}favicons/apple-icon-76x76.png`,
//     },
//     {
//       rel: 'apple-touch-icon',
//       sizes: '114x114',
//       href: `${basePath}favicons/apple-icon-114x114.png`,
//     },
//     {
//       rel: 'apple-touch-icon',
//       sizes: '120x120',
//       href: `${basePath}favicons/apple-icon-120x120.png`,
//     },
//     {
//       rel: 'apple-touch-icon',
//       sizes: '144x144',
//       href: `${basePath}favicons/apple-icon-144x144.png`,
//     },
//     {
//       rel: 'apple-touch-icon',
//       sizes: '152x152',
//       href: `${basePath}favicons/apple-icon-152x152.png`,
//     },
//     {
//       rel: 'apple-touch-icon',
//       sizes: '180x180',
//       href: `${basePath}favicons/apple-icon-180x180.png`,
//     },
//     {
//       rel: 'icon',
//       type: 'image/png',
//       sizes: '192x192',
//       href: `${basePath}favicons/android-icon-192x192.png`,
//     },
//     {
//       rel: 'icon',
//       type: 'image/png',
//       sizes: '32x32',
//       href: `${basePath}favicons/favicon-32x32.png`,
//     },
//     {
//       rel: 'icon',
//       type: 'image/png',
//       sizes: '96x96',
//       href: `${basePath}favicons/favicon-96x96.png`,
//     },
//     {
//       rel: 'icon',
//       type: 'image/png',
//       sizes: '48x48',
//       href: `${basePath}favicons/favicon-48x48.png`,
//     },
//     {
//       rel: 'icon',
//       type: 'image/png',
//       sizes: '16x16',
//       href: `${basePath}favicons/favicon-16x16.png`,
//     },
//     {
//       rel: 'manifest',
//       href: `${basePath}favicons/manifest.json`,
//     },
//   ];
// }

export function normalizePath(path) {
  if (path.startsWith('/')) {
    return path;
  }

  return `/${path}`;
}

export function prepareAssetPath(path) {
  if (!path) {
    return null;
  }

  return `/assets-proxy${normalizePath(path)}`;
}

const HEADER_X_FORWARDED_FOR = 'X-Forwarded-For';
const HEADER_X_FORWARDED_FOR_ALT = 'x-forwarded-for';
const HEADER_X_REAL_IP = 'X-Real-IP';
const HEADER_X_REAL_IP_ALT = 'x-real-ip';

function factoryFromHeader(name) {
  return ({ headers }) => {
    if (!headers) {
      return null;
    }

    if (!headers[name]) {
      return null;
    }

    return getFirstIp(headers[name]);
  };
}

function getFirstIp(ips) {
  if (!ips) {
    return null;
  }

  const firstAddress = ips.split(',')[0];

  if (!firstAddress) {
    return null;
  }

  return firstAddress.trim();
}

const IP_STRATEGIES = [
  ({ geo }) => {
    if (!geo) {
      return null;
    }

    return geo.ip;
  },
  factoryFromHeader(HEADER_X_FORWARDED_FOR),
  factoryFromHeader(HEADER_X_FORWARDED_FOR_ALT),
  factoryFromHeader(HEADER_X_REAL_IP),
  factoryFromHeader(HEADER_X_REAL_IP_ALT),
  ({ event }) => {
    if (!event) {
      return null;
    }

    return event.node.req.socket.remoteAddress;
  },
  ({ event }) => {
    if (!event) {
      return null;
    }

    return event.node.req.connection.remoteAddress;
  },
];

function isValidIP(ip) {
  if (!ip) {
    return false;
  }

  if (ip === '::1') {
    return false;
  }

  if (ip === '127.0.0.1') {
    return false;
  }

  return true;
}

export function getIp(input) {
  for (const strategy of IP_STRATEGIES) {
    const ip = strategy(input);

    if (isValidIP(ip)) {
      return ip;
    }
  }

  return null;
}

export function isNumeric(str) {
  if (typeof str !== 'string') {
    return false;
  }

  if (isNaN(str)) { // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    return false;
  }

  if (isNaN(parseFloat(str))) { // ensure strings of whitespace fail
    return false;
  }

  return true;
}
