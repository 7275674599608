<template>
  <component
    :is="rootComponent"
    class="d-flex justify-content-between py-2 px-1 text-white text-decoration-none border-bottom"
    :to="to"
  >
    <small>
      {{ match.home.name }} - {{ match.away.name }}
    </small>

    <small class="fs-6 text-nowrap">
      {{ startTime }}
    </small>
  </component>
</template>

<script>
import { defineComponent } from 'vue';
import {
  DATE_dd_MM,
  DATE_HH_mm,
} from '~/config/global';

export default defineComponent({
  name: 'FooterMatchesItem',

  props: {
    match: {
      required: true,
      type: Object,
    },
  },

  computed: {
    rootComponent() {
      const { page } = this.match;

      if (!page) {
        return 'div';
      }

      return resolveComponent('nuxt-link');
    },

    to() {
      const { page } = this.match;

      if (!page) {
        return null;
      }

      return this.localePath({ name: 'match-slug', params: { slug: page.slug } });
    },

    startTime() {
      const { start_time } = this.match;
      const date = this.$dateFns.format(start_time, DATE_dd_MM);
      const time = this.$dateFns.format(start_time, DATE_HH_mm);

      return `${date} | ${time}`;
    },
  },
});
</script>
