<template>
  <default-layout>
    <NuxtPage/>
  </default-layout>
</template>

<script setup lang="ts">
import DefaultLayout from './components/general/DefaultLayout.vue';

const i18nHead = useLocaleHead({
  identifierAttribute: 'id',
  addDirAttribute: true,
  addSeoAttributes: true,
});

useHead({
  ...i18nHead.value,
});
</script>
