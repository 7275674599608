import VueGtag, { trackRouter } from 'vue-gtag-next';
// import VueGtag from 'vue-gtag-next';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig().public;
  // const isProduction = config.ENV === 'production';
  const gtmDebug = config.GTM_DEBUG === 'true';
  const routeDebug = useRoute().query.gtmdebug === 'true';
  const GTM_ID = 'G-N5NX2L9S95';

  nuxtApp.vueApp.use(VueGtag, {
    property: {
      id: GTM_ID,
      send_page_view: false,
    },
    // defer: false, resourceURL: "https://www.googletagmanager.com/gtag/js",
    isEnabled: true,
    useDebugger: gtmDebug || routeDebug,
    // disableScriptLoader: !isProduction,
    disableScriptLoader: true,
  });
  trackRouter(useRouter(), {
    template(to, from) {
      return {
        page_title: document.title,
        page_path: to.path,
        page_location: window.location.href,
      };
    },
  });
});
