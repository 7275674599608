export class DateFnsError extends Error {
  constructor(originalError, date, timezone) {
    super(
      `${originalError.message}; date[${date}]; timezone[${timezone}]`,
      { cause: originalError },
    );

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DateFnsError);
    } else {
      // eslint-disable-next-line unicorn/error-message
      this.stack = (new Error()).stack;
    }

    this.name = 'DateFnsError';
  }
}
