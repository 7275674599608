import { default as indext9xHbOLwHhMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/about/index.vue?macro=true";
import { default as indexp8OzCsqBSDMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/contact/index.vue?macro=true";
import { default as indexZNaIcYma0XMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/index.vue?macro=true";
import { default as _91slug_93CBwR0G9l6jMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/league/[slug].vue?macro=true";
import { default as _91slug_93dpCO2inkksMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/match/[slug].vue?macro=true";
import { default as indexUO9oGFb2EbMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/privacy-policy/index.vue?macro=true";
export default [
  {
    name: "about___es-mx",
    path: "/sobre-nosotros",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___es-mx",
    path: "/contacto",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es-mx",
    path: "/",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "league-slug___es-mx",
    path: "/liga/:slug()",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/league/[slug].vue").then(m => m.default || m)
  },
  {
    name: "match-slug___es-mx",
    path: "/noticias/:slug()",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/match/[slug].vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___es-mx",
    path: "/politica-de-privacidad",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20240823123455447/pages/privacy-policy/index.vue").then(m => m.default || m)
  }
]