export const createBaseConfig = (env) => ({
  // enabled: env.NODE_ENV !== 'developement',
  enabled: !!env.SENTRY_DSN,
  dsn: env.SENTRY_DSN || '', // Enter your project's DSN here
  release: env.SENTRY_RELEASE_VERSION || '', // Enter your project's DSN here
  environment: env.SENTRY_ENVIRONMENT || env.APP_ENV || (process.dev ? 'development' : 'production'),
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    'Can\'t find variable: ZiteReader',
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Avast extension error
    '_avast_submit',
    'Script error.',
    'Network Error',
    'Request aborted',
    'timeout of 0ms exceeded',
    /Loading chunk .*? failed\\.|Loading CSS chunk .*? failed\\./,
    /error loading dynamically imported module.*/,
    /Unable to preload CSS for.*/,
    /SecurityError: Failed to read the 'cssRules' property from 'CSSStyleSheet'/,
    /Navigation cancelled from .*?/,
    /Avoided redundant navigation to current location.*?/,
    /ReportingObserver.*/,
    /SecurityError: Blocked a frame with origin .* from accessing a cross-origin frame\./,
    /Request failed with status code 50.*/,
    'adsbygoogle.push() error: All ins elements in the DOM with class=adsbygoogle already have ads in them.',
    'Identifier \'isMainframe\' has already been declared',
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'ResizeObserver loop limit exceeded',
    'NavigationDuplicated',
    'Unexpected token \'<\'',
    'Load failed',
    'Failed to fetch',
    'NetworkError when attempting to fetch resource.',
    'Can\'t find variable: ZiteReader',
    'ibFindAllVideos is not defined',
    'timeout exceeded',
    'b.loadElementCss is not a function',
    'cancelled',
    'Request failed with status code 404',
    'Request failed with status code 422',
    /__gCrWeb/i,
    'Cannot redefine property: BetterJsPop',
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Clever ads errors
    /cleverwebserver\.com/i,
    /scripts\.cleverwebserver\.com/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /extension:\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /^safari-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /notix\.io/i,
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  tracesSampleRate: 0.01,
});
