<template>
  <div class="row">
    <div
      v-for="(col, colIndex) in matchCols"
      :key="colIndex"
      class="col-md-6"
    >
      <footer-matches-item
        v-for="match in col"
        :key="match.id"
        :match="match"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {
  DATE_YYYY_MM_DD,
  FOOTER_MATCH_COUNT,
  HOMEPAGE_DAYS_MORE,
} from '~/config/global';
import { CACHE_KEY_SPORT_EVENTS, TIME_HOUR } from '~/config/cache';
import FooterMatchesItem from '~/components/general/FooterMatchesItem.vue';

export default defineComponent({
  name: 'FooterMatches',
  components: { FooterMatchesItem },

  async setup() {
    const app = useNuxtApp();
    const { $dateFns, $cacheFetch } = app;
    const timezone = useTimezone().value;

    const today = new Date();
    const from = $dateFns.format(today, DATE_YYYY_MM_DD);
    const to = $dateFns.format($dateFns.addDays(today, HOMEPAGE_DAYS_MORE), DATE_YYYY_MM_DD);
    const statuses = 'not_started,posponed';
    const params = {
      from,
      to,
      limit: FOOTER_MATCH_COUNT,
      statuses,
      tz: timezone,
      offset: 0,
      has_transmissions: 1,
      geo_alpha2: 'MX',
    };

    const { data: events, error } = await useAsyncData('footer-matches', () => $cacheFetch({
      url: '/soccer/api/sport-events',
      key: parseCacheKey(CACHE_KEY_SPORT_EVENTS, params),
      params,
      maxAge: TIME_HOUR,
    }));

    if (error.value) {
      throw error.value;
    }

    return { events };
  },

  computed: {
    matchCols() {
      const { events } = this;

      if (!events) {
        return [];
      }

      const { data } = events;

      if (!Array.isArray(data)) {
        return [];
      }

      return chunk(data, Math.ceil(data.length / 2));
    },
  },
});
</script>
