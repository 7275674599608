import {
  init,
  vueRouterInstrumentation,
  BrowserTracing,
  attachErrorHandler,
  setContext,
  setUser,
  setTag,
  addBreadcrumb,
  captureException,
  createTracingMixins,
} from '@sentry/vue';
import { createBaseConfig } from '../config/sentry';
import { getRandomNumber } from '../utils/common';

const IGNORE_ERRORS_90 = [
  'Cannot read properties of null (reading \'subTree\')',
  'Importing a module script failed.',
];

const IGNORE_ERRORS_99 = [
  'Event `Event` (type=error) captured as promise rejection',
  'Failed to load: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js*"',
];

function shouldBeIgnore(originalException, ignoreList, ignoreRate) {
  if (!originalException) {
    return false;
  }

  if (!new RegExp(ignoreList.join('|')).test(originalException.toString())) {
    return false;
  }

  if (getRandomNumber(0, 100) < ignoreRate) {
    return false;
  }

  return true;
}

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const config = useRuntimeConfig().public;
  const baseConfig = createBaseConfig(config);

  init({
    enableTracing: true,
    app: [vueApp],
    attachProps: true,
    // debug: true,
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    ...baseConfig,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation(nuxtApp.$router),
      }),
    ],
    beforeSend (event, hint) {
      const { originalException } = hint;
      if (shouldBeIgnore(originalException, IGNORE_ERRORS_99, 99)) {
        return null;
      }

      if (shouldBeIgnore(originalException, IGNORE_ERRORS_90, 90)) {
        return null;
      }

      // Check if it is an exception, and if so, log it.
      if (event.exception && config.APP_ENV === 'production') {
        // eslint-disable-next-line no-console
        console.error(`[Exeption handled by Sentry]: (${originalException})`, { event, hint });
      }

      if (event.exception.extra) {
        event.extra = {
          ...event.extra,
          ...event.exception.extra,
        };
      }

      // Continue sending to Sentry
      return event;
    },
    allowUrls: [
      'localhost',
      /sporticos\.com/i,
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });

  vueApp.mixin(createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }));
  attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] });
  setTag('app_side', 'client');

  return {
    provide: {
      sentryCaptureException: captureException,
      sentry: {
        setContext,
        setUser,
        setTag,
        addBreadcrumb,
        captureException,
      },
    },
  };
});
