import { diff } from './diff';

export function parseCacheKeyWithWildcards(template) {
  const variables = template.match(/{([^}]*)}/g);

  if (!Array.isArray(variables)) {
    return template;
  }

  const keys = variables
    .map((key) => key.replace(/[{}]/g, ''));
  const params = keys.reduce((acc, key) => ({
    ...acc,
    [key]: '*',
  }), {});

  return parseCacheKey(template, params);
}

export function parseCacheKey(template, params = {}) {
  const variables = template.match(/{([^}]*)}/g);

  if (!Array.isArray(variables)) {
    return template;
  }

  const keys = variables
    .map((key) => key.replace(/[{}]/g, ''));

  const passedKeys = Object.keys(params);
  const missingKeys = diff(keys, passedKeys);

  if (missingKeys.length > 0) {
    throw new Error(`Template "${template}" is missing following keys: ${missingKeys.join(', ')}`);
  }

  // const unusedKeys = diff(passedKeys, keys);

  // if (unusedKeys.length > 0) {
  //   // eslint-disable-next-line no-console
  //   console.warn(`Template "${template}" does not have variables for: ${unusedKeys.join(', ')}`);
  // }

  return keys
    .reduce(
      (acc, key) => acc.replace(
        new RegExp(`{${key}}`, 'g'),
        params[key],
      ),
      template,
    );
}
