<template>
  <footer class="text-bg-dark bg-black pt-5 pb-3">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mb-3">
          <div class="pe-md-4 border-end no-border-sm">
            <h3 class="mb-2">
              {{ $t('footer.matches.heading') }}
            </h3>

            <footer-matches/>
          </div>
        </div>

        <div class="col-md-4 mb-3">
          <h3>{{ $t('footer.description.heading') }}</h3>

          <p class="text-gray-400">
            {{ $t('footer.description.content') }}
          </p>
        </div>

        <div class="pt-4">
          <div class="row">
            <div class="col-md-4 d-flex justify-content-center justify-content-md-start">
              <small>
                futbolhoyenvivo.mx©
                <br>
                Gamble Responsibly 18+
              </small>
            </div>

            <div class="col-md-4 d-flex align-items-center justify-content-center">
              <small class="py-2 my-2">
                <!--                <nuxt-link :to="localePath({name: 'about'})" class="text-white mx-1">
                                  {{ $t('footer.link.about') }}
                                </nuxt-link>-->

                <nuxt-link
                  class="text-white mx-1"
                  :to="localePath({ name: 'contact' })"
                >
                  {{ $t('footer.link.contact') }}
                </nuxt-link>

                <nuxt-link
                  class="text-white mx-1"
                  :to="localePath({ name: 'privacy-policy' })"
                >
                  {{ $t('footer.link.privacy_policy') }}
                </nuxt-link>
              </small>
            </div>

            <div class="col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
              <a
                class="d-inline-flex align-items-center p-1 bg-white rounded text-nowrap"
                href="https://www.gambleaware.org/"
              >
                <img
                  class="me-1"
                  height="24"
                  loading="lazy"
                  src="@/assets/images/logo/begambleawareorg-18.svg"
                  width="24"
                >

                <img
                  alt="BeGambleAware.org"
                  height="16"
                  loading="lazy"
                  src="@/assets/images/logo/gamble-aware.svg"
                  width="123"
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterMatches from '~/components/general/FooterMatches.vue';

export default {
  name: 'DefaultFooter',

  components: {
    FooterMatches,
  },
};
</script>
