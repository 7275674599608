import {
  HEADER_CACHE_IGNORE_GEO,
  HEADER_CACHE_IGNORE_LOCALE,
  HEADER_CACHE_KEY,
  HEADER_CACHE_MAX_AGE,
  HEADER_CACHE_SKIP,
  TIME_MINUTE,
} from '../config/cache';
import { DOMAIN_MX } from '~/config/global';

function getLocaleValue(locale) {
  if (typeof locale === 'string') {
    return locale;
  }

  return locale.value;
}

function normalizeUrl(url) {
  if (!url.startsWith('/')) {
    return `/${url}`;
  }

  return url;
}

export default defineNuxtPlugin((nuxtApp) => {
  const locale = getLocaleValue(nuxtApp.$i18n.locale);
  const { $axios, $cache } = nuxtApp;

  async function cacheFetch(options) {
    const {
      url,
      key,
      maxAge = TIME_MINUTE,
      params = {},
      timeout,
      skipCache = false,
      isSelfRequest = false,
      ignoreGeo = false,
      ignoreLocale = false,
    } = options;

    if (!url) {
      throw new Error('"url" must be provided for cacheFetch');
    }

    if (!key) {
      throw new Error('"key" must be provided for cacheFetch');
    }

    const config = {
      params,
      timeout,
      isSelfRequest,
    };

    if (skipCache === true) {
      const { data } = await $axios(
        url,
        {
          ...config,
          headers: {
            [HEADER_CACHE_SKIP]: true,
          },
        },
      );

      return data;
    }

    return $cache(
      key,
      async () => {
        const { data } = await $axios(
          url,
          {
            ...config,
            headers: {
              [HEADER_CACHE_KEY]: key,
              [HEADER_CACHE_MAX_AGE]: maxAge,
              [HEADER_CACHE_IGNORE_GEO]: ignoreGeo,
              [HEADER_CACHE_IGNORE_LOCALE]: ignoreLocale,
            },
          },
        );

        return data;
      },
      {
        maxAge,
        ignoreGeo,
        ignoreLocale,
      },
    );
  }

  function cacheFetchDomain(options) {
    return cacheFetch({
      ...options,
      url: `/soccer/api/${DOMAIN_MX}${normalizeUrl(options.url)}`,
    });
  }

  function cacheFetchSoccer(options) {
    return cacheFetch({
      ...options,
      url: `/api/${locale}/soccer${normalizeUrl(options.url)}`,
    });
  }

  function cacheFetchSports(options) {
    return cacheFetch({
      ...options,
      url: `/api/${locale}/new-sports/v1${normalizeUrl(options.url)}`,
    });
  }

  function cacheFetchNews(options) {
    return cacheFetch({
      ...options,
      isSelfRequest: true,
      url: `/api/news-proxy${normalizeUrl(options.url)}`,
    });
  }

  function cacheFetchSelf(options) {
    return cacheFetch({
      ...options,
      isSelfRequest: true,
      url: normalizeUrl(options.url),
    });
  }

  return {
    provide: {
      cacheFetch,
      cacheFetchDomain,
      cacheFetchSoccer,
      cacheFetchNews,
      cacheFetchSports,
      cacheFetchSelf,
    },
  };
});
