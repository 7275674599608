export default function useUserTimezone() {
  const geo = useGeo();

  return computed(() => {
    if (!geo) {
      return null;
    }

    const { value } = geo;

    if (!value) {
      return null;
    }

    return value.timezone;
  });
}
