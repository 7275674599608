import { CACHE_KEY_GEO, TIME_DAY } from '../config/cache';
import { parseCacheKey } from '../utils/cacheHelpers';
import { getIp } from '~/utils/common';

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $axios, $cache, $sentry, $i18n } = nuxtApp;
  const config = useRuntimeConfig();
  const geoState = useGeo();
  const headers = useRequestHeaders();
  const event = useRequestEvent();

  if ($sentry) {
    $sentry.setTag('locale', $i18n.locale.value);
    $sentry.setTag('app_env', config.public.APP_ENV);

    if (geoState.value) {
      $sentry.setTag('geo', geoState.value.country_iso);
      $sentry.setTag('geo_timezone', geoState.value.timezone);
    }
  }

  if (geoState.value) {
    return;
  }

  const ip = getIp({ headers, event });
  // const ip = null;

  try {
    const response = await $cache(
      parseCacheKey(CACHE_KEY_GEO, { ip }),
      async () => {
        const { data } = await $axios.get('/soccer/api/geo/me', {
          headers: {
            'x-forwarded-for': ip,
            'x-real-client-ip': ip,
            'x-real-ip': ip,
            xRealClientIp: ip,
          },
        });

        return data;
      },
      {
        // skipCache: true,
        ignoreLocale: true, // geo is per IP, selected language has nothing to do with it
        ignoreGeo: true, // geo is per IP, selected language has nothing to do with it
        maxAge: TIME_DAY,
      },
    );

    response.detectedIp = ip;

    if ($sentry) {
      $sentry.setTag('geo', response.country_iso);
      $sentry.setTag('geo_timezone', response.timezone);
    }

    geoState.value = response;
  } catch (e) {
    console.error('e', e);
  }
});
