import axios from 'axios';

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig().public;
  const client = axios.create({
    baseURL: runtimeConfig.BASE_BROWSER_API_URL,
  });
  const geoState = useGeo();

  client.interceptors.request.use((config) => {
    const {
      country_iso,
      country_name,
      timezone,
      ip,
    } = geoState.value || {};

    config.headers['X-APP-LOCALE'] = nuxtApp.$i18n.locale.value;

    if (country_iso) {
      config.headers['x-client-geo-iso'] = country_iso;
    }

    if (country_name) {
      config.headers['x-client-geo-name'] = country_name;
    }

    if (timezone) {
      config.headers['x-client-geo-tz'] = timezone;
    }

    if (ip && !config.headers['x-real-client-ip']) {
      config.headers['x-real-client-ip'] = ip;
    }

    if (config.isSelfRequest) {
      config.baseURL = runtimeConfig.BASE_FRONT_URL;

      if (runtimeConfig.APP_ENV === 'staging') {
        config.headers.authorization = 'Basic c3A6c3A=';
        config.headers.Authorization = 'Basic c3A6c3A=';
      }
    }

    return config;
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;

      if (response) {
        error.statusCode = response.status;
      }

      return Promise.reject(error);
    },
  );

  return {
    provide: {
      axios: client,
    },
  };
});
