import { addDays, formatISO } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';
// import { format, formatInTimeZone } from 'date-fns-tz';
import es from 'date-fns/locale/es';
import { DATE_YYYY_MM_DD } from '~/config/global';
import { DateFnsError } from '~/utils/errors/DateFnsError';

const locales = {
  es,
  'es-mx': es,
};

function normalizeDate(input, timezone, options) {
  // const originalDate = typeof input === 'string' ? parseISO(input) : input;

  return toZonedTime(input, timezone, options);
}

export function createDateUtils(inp) {
  const { getTimeZone, selectedLocale } = inp;
  const locale = locales[selectedLocale];

  return {
    format(originalDate, dateFormat = DATE_YYYY_MM_DD, opts) {
      const timeZone = getTimeZone();
      const options = {
        ...(opts || {}),
        locale,
        timeZone,
      };

      const date = normalizeDate(originalDate, timeZone, options);

      try {
        return format(date, dateFormat, options);
      } catch (e) {
        throw new DateFnsError(e, originalDate, timeZone);
      }
    },
    formatISO(originalDate, opts) {
      const timeZone = getTimeZone();
      const options = {
        ...(opts || {}),
        locale,
        timeZone,
      };
      const date = normalizeDate(originalDate, timeZone, options);

      try {
        return formatISO(date, options);
      } catch (e) {
        throw new DateFnsError(e, originalDate, timeZone);
      }
    },
    addDays,
    getTimeZone: () => getTimeZone(),
    normalizeDate(input, tz, opts) {
      const timeZone = tz || getTimeZone();
      const options = {
        ...(opts || {}),
        locale,
        timeZone,
      };

      return normalizeDate(input, timeZone, options);
    },
  };
}
