import { TZ_MEXICO_CITY, TZ_PARIS, USE_FIXED_TIMEZONE } from '~/config/global';
import { createDateUtils } from '~/utils/dateUtils';

export default defineNuxtPlugin((nuxtApp) => {
  const selectedLocale = nuxtApp.$i18n.locale.value;
  // const geoState = useGeo();
  const timezone = useTimezone();

  const $dateFns = createDateUtils({
    selectedLocale,
    getTimeZone() {
      const tz = timezone.value;
      if (USE_FIXED_TIMEZONE) {
        return TZ_MEXICO_CITY;
      }

      // const geo = geoState.value;
      //
      // if (!geo) {
      //   return TZ_PARIS;
      // }
      //
      // const { timezone } = geo;

      if (!tz) {
        return TZ_PARIS;
      }

      return tz;
    },
  });

  return {
    provide: {
      dateFns: $dateFns,
    },
  };
});
