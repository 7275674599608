import { isClient } from '../utils/isClient';

let promise = null;

export function delay(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export function deferLoad() {
  if (!isClient()) {
    throw new Error('Defer loading should only be used in client context!');
  }

  if (promise === null) {
    promise = new Promise((resolve) => {
      const loadHandle = ({ target }) => {
        target.removeEventListener('load', loadHandle);
        resolve();
      };

      if (document.readyState === 'complete') {
        resolve();
      } else {
        window.addEventListener('load', loadHandle);
      }
    });
  }

  return promise;
}
