<template>
  <div class="d-flex flex-column layout-root bg-gray-200 min-vh-100">
    <ClientOnly>
      <NuxtLoadingIndicator
        color="#0eb04a"
        :height="4"
        :throttle="0"
      />
    </ClientOnly>

    <top-nav/>

    <main class="mt-4 mb-6 flex-grow-1">
      <slot/>
    </main>

    <default-footer/>
  </div>
</template>

<script>
import { GLOBAL_GOOGLE_ADS_ENABLED, GLOBAL_GOOGLE_OPTIMIZE_ENABLED } from '../../config/global';
import { deferLoad } from '../../utils/deferLoad';
import loadExternalScript from '../../utils/externalResources';
import DefaultFooter from './DefaultFooter';
import TopNav from '~/components/general/TopNav.vue';

export default {
  name: 'DefaultLayout',

  components: {
    TopNav,
    DefaultFooter,
  },

  setup() {
    return {
      countryIsoCode: useGeoIsoCode(),
    };
  },

  data() {
    return {
      contentReady: false,
    };
  },

  mounted() {
    this.initializeScripts();
  },

  methods: {
    async initializeScripts() {
      this.contentReady = true;

      await deferLoad();

      this.initializeGoogleAds();
      this.initializeOptimize();
    },

    async initializeOptimize() {
      if (!GLOBAL_GOOGLE_OPTIMIZE_ENABLED) {
        return;
      }

      try {
        await loadExternalScript('https://www.googleoptimize.com/optimize.js?id=GTM-NZ9W5PW');
      } catch (e) {
        console.error('Failed to load Optimize.');
      }
    },

    async initializeGoogleAds() {
      if (!GLOBAL_GOOGLE_ADS_ENABLED) {
        return;
      }

      const client = this
        .$config
        .public
        .GOOGLE_ADS_AD_CLIENT;

      if (!client) {
        return;
      }

      try {
        await loadExternalScript(
          `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${client}`,
          {
            crossOrigin: 'anonymous',
          },
        );
      } catch (e) {
        console.error('Failed to load Google Ads with message:', e);
      }
    },
  },
};
</script>
