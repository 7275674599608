import { TZ_MEXICO_CITY, USE_FIXED_TIMEZONE } from '~/config/global';

export default function useTimezone() {
  const userTimezone = useUserTimezone();

  return computed(() => {
    if (USE_FIXED_TIMEZONE) {
      return TZ_MEXICO_CITY;
    }

    return userTimezone.value;
  });
}
