import { shouldPolyfill } from '@formatjs/intl-relativetimeformat/should-polyfill';

export default defineNuxtPlugin(async (nuxtApp) => {
  const locale = nuxtApp.$i18n.locale.value;

  try {
    await polyfillIntlRelativeTime(locale);
  } catch (e) {
  }
});

async function polyfillIntlRelativeTime(locale) {
  const unsupportedLocale = shouldPolyfill();
  if (!unsupportedLocale) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-relativetimeformat/polyfill');
  await import('@formatjs/intl-relativetimeformat/locale-data/es');
}
